import { translations } from 'locales/translations';
import { _t } from 'locales/utils/messages';

export const messages = {
  emailAddressLabel: () =>
    _t(
      translations.components.AccountCard.labels.emailAddressLabel,
      'Email address'
    ),
  passwordLabel: () =>
    _t(
      translations.components.AccountCard.labels.passwordLabel,
      'Enter password'
    ),
  repeatPasswordLabel: () =>
    _t(
      translations.components.AccountCard.labels.repeatPasswordLabel,
      'Repeat password'
    ),
};
