import React from 'react';
import { Box } from '@mui/material';

import { ReactComponent as EyeClosed } from 'assets/svg/eye-closed.svg';
import { ReactComponent as EyeOpen } from 'assets/svg/eye-open.svg';
import { ReactComponent as InputValid } from 'assets/svg/input-valid.svg';

import { StyledIconButton } from './TextFieldIcons.styled';

export enum TestId {
  PasswordVisibilityButton,
  ShowPasswordIcon,
  HidePasswordIcon,
  InputValidIcon,
}

interface Props {
  isPassword: boolean;
  passwordVisible: boolean;
  visibilityCallback: () => void;
  isValid: boolean;
}

const TextFieldIcons: React.FC<Props> = ({
  isPassword,
  passwordVisible,
  visibilityCallback,
  isValid,
}) => {
  const visibilityIcon = passwordVisible ? (
    <EyeClosed data-testid={TestId.HidePasswordIcon} />
  ) : (
    <EyeOpen data-testid={TestId.ShowPasswordIcon} />
  );
  return (
    <>
      {isPassword && (
        <StyledIconButton
          aria-label={passwordVisible ? 'hide password' : 'show password'}
          onClick={visibilityCallback}
          data-testid={TestId.PasswordVisibilityButton}
        >
          {visibilityIcon}
        </StyledIconButton>
      )}
      {isValid && (
        <Box marginX="14px" display="flex" alignItems="center">
          <InputValid data-testid={TestId.InputValidIcon} />
        </Box>
      )}
    </>
  );
};

export default TextFieldIcons;
