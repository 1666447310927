import styled from '@emotion/styled';
import { colors, theme } from 'theme';

export const Container = styled.div<{ isSecondaryBackground: boolean }>(
  ({ isSecondaryBackground }) => ({
    background: isSecondaryBackground
      ? colors.paleBlue400
      : colors.limeGreen500,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem 3.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 1.5rem',
    },
  })
);

export const Main = styled.main({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
