import { translations } from 'locales/translations';
import { _t } from 'locales/utils/messages';

export const messages = {
  copyright: () =>
    _t(translations.components.Footer.copyright, 'Pip Care Inc.'),
  terms: () => _t(translations.components.Footer.terms, 'Terms of service'),
  privacy: () => _t(translations.components.Footer.privacy, 'Privacy policy'),
  contact: () => _t(translations.components.Footer.contact, 'Contact'),
};
