import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '@mui/material';

import { StyledButton, StyledCircularProgress } from './Button.styled';
import { messages } from './messages';

interface Props extends ButtonProps {
  loading?: boolean;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'disabled';
}

const Button: React.FC<PropsWithChildren<Props>> = ({
  loading,
  disabled,
  variant = 'primary',
  children,
  ...restProps
}) => {
  const { t } = useTranslation();

  const label = children || t(messages.defaultLabel());

  return (
    <StyledButton
      variant={loading || disabled ? 'disabled' : variant}
      disabled={disabled || loading}
      {...restProps}
    >
      {loading ? <StyledCircularProgress size={24} /> : label}
    </StyledButton>
  );
};

export default Button;
