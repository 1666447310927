import React from 'react';
import { Box } from '@mui/material';

import { ReactComponent as ValidationFail } from 'assets/svg/error-icon.svg';
import { ReactComponent as ValidationDefault } from 'assets/svg/validation-default.svg';
import { ReactComponent as ValidationSuccess } from 'assets/svg/validation-success.svg';
import Typography from 'components/Typography';

export enum TestId {
  NotCheckedStatus,
  ValidStatus,
  InvalidStatus,
}

export enum ValidationStatus {
  NotChecked = 'NotChecked',
  Valid = 'Valid',
  Invalid = 'Invalid',
}

interface Props {
  description: string;
  status: ValidationStatus;
  validation?: RegExp;
  password?: string;
}

const PasswordValidationCheck: React.FC<Props> = ({ description, status }) => {
  const determineIcon = () => {
    switch (status) {
      case ValidationStatus.Valid:
        return <ValidationSuccess data-testid={TestId.ValidStatus} />;
      case ValidationStatus.Invalid:
        return <ValidationFail data-testid={TestId.InvalidStatus} />;
      default:
        return <ValidationDefault data-testid={TestId.NotCheckedStatus} />;
    }
  };

  return (
    <Box display="flex" alignItems="center" columnGap={1} marginBottom="4px">
      {determineIcon()}
      <Typography variant="Helper1" color={status === 'Invalid' ? 'error' : 'darkGreen700'}>
        {description}
      </Typography>
    </Box>
  );
};

export default PasswordValidationCheck;
