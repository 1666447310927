import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/types/RootState';

import { initialState } from '.';

const selectSlice = (state: RootState) => state?.registration || initialState;

export const selectRegistrationStep = createSelector(
  [selectSlice],
  (state) => state.registrationStep
);
