import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { Routes } from 'navigation/routes';
import { theme } from 'theme';

import UnderlineSvg from 'assets/svg/underline-1.svg';
import Button from 'components/Button';
import LottieAnimation from 'components/LottieAnimation';
import StepsBox from 'components/StepsBox';
import useContentfulEntry from 'hooks/useContentfulEntry';

import { Container, ContentContainer, UnderlineContainer, UnderlineImage } from './Home.styled';

const Home = () => {
  const content = useContentfulEntry('4sY18waYRpsFTqeIIm56a6');
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const buttonRedirect = () => {
    navigate(Routes.GetStarted);
  };

  if (!content) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Box>
        <Typography variant={isSmScreen ? 'Headline1' : 'MainTitle'} textAlign="center">
          <>
            {content.title}
            <UnderlineContainer>
              .
              <UnderlineImage src={UnderlineSvg} alt="custom underline" />
            </UnderlineContainer>
          </>
        </Typography>
      </Box>

      {!isSmScreen && <Typography variant="Headline2">{content.content}</Typography>}
      <ContentContainer>
        <Box />
        <StepsBox contentList={content.list}></StepsBox>
        <LottieAnimation width={220} height={220} />
      </ContentContainer>
      <Button variant="secondary" onClick={buttonRedirect} />
    </Container>
  );
};

export default Home;
