import styled from '@emotion/styled';
import { colors, theme } from 'theme';

export const Container = styled.div({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

export const BackgroundContainer = styled.div({
  background: colors.darkGreen100,
  [theme.breakpoints.down('sm')]: {
    background: colors.white,
  },
});

export const PadContainer = styled.div({
  padding: '2rem 3.5rem',
  [theme.breakpoints.down('sm')]: {
    padding: '1rem 1.5rem',
  },
});

export const Main = styled.main({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
