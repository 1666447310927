import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { colors, theme } from 'theme';

export const Footer = styled.footer({
  display: 'flex',
  gap: 32,
  marginTop: 12,
  [theme.breakpoints.down('sm')]: {
    gap: 8,
    flexDirection: 'column',
    alignItems: 'center',
    borderTop: `1px solid ${colors.darkGreen500}`,
    paddingTop: 24,
  },
});

export const NavLinkNoUnderline = styled(NavLink)({
  textDecoration: 'none',
});

export const LinkContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 32,
  [theme.breakpoints.down('sm')]: {
    gap: 8,
  },
});
