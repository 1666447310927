import styled from '@emotion/styled';
import { colors } from 'theme';

export const Container = styled.div({
  display: 'flex',
  columnGap: 4,
  marginBottom: 30,
});

export const Step = styled.span<{ isActive: boolean }>(({ isActive }) => ({
  width: '100%',
  height: 4,
  backgroundColor: isActive ? colors.limeGreen500 : colors.darkGreen200,
  borderRadius: 2,
}));

export const StepSecondary = styled(Step)<{ isActive: boolean }>(
  ({ isActive }) => ({
    backgroundColor: isActive ? colors.darkGreen900 : colors.white,
  })
);
