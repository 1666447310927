import React from 'react';

import { Container, Step, StepSecondary } from './CardSteps.styled';

export enum TestId {
  InactiveStep,
  ActiveStep,
}

interface Props {
  currentStep?: number;
  numberOfSteps?: number;
  isSecondaryColor?: boolean;
}

const CardSteps: React.FC<Props> = ({
  currentStep,
  numberOfSteps = 3,
  isSecondaryColor,
}) => {
  if (!currentStep) return null;

  return (
    <Container>
      {Array.from({ length: numberOfSteps }, (_, i) => {
        const isActive = i + 1 <= currentStep;
        const testId = isActive ? TestId.ActiveStep : TestId.InactiveStep;

        return isSecondaryColor ? (
          <StepSecondary key={i} isActive={isActive} data-testid={testId} />
        ) : (
          <Step key={i} isActive={isActive} data-testid={testId} />
        );
      })}
    </Container>
  );
};

export default CardSteps;
