import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import { colors } from 'theme';

export const StyledFormControlLabel = styled(FormControlLabel)({
  alignItems: 'flex-start',
  columnGap: 10,
  margin: 0,
});

export const HiddenCheckbox = styled.input({
  position: 'absolute',
  opacity: 0,
  height: 0,
  width: 0,
});

export const DefaultCheckbox = styled.div({
  width: 20,
  height: 20,
  border: `2px solid ${colors.darkGreen600}`,
  borderRadius: 4,
  flexShrink: 0,
  marginTop: 4,
  transition: 'all .3s',
});

export const CheckedCheckbox = styled(DefaultCheckbox)({
  border: 'none',
  backgroundColor: colors.limeGreen500,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
