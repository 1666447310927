export enum RegistrationStep {
  Account,
  PhoneNumber,
  Basics,
}

interface UserData {
  email?: string;
  password?: string;
  phone?: string;
  name?: string;
  lastName?: string;
  birth?: string;
}

export interface RegistrationState {
  registrationStep: RegistrationStep;
  userData?: UserData;
}
