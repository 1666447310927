import axios, { AxiosInstance } from 'axios';
import Config from 'config';
import { Entry } from 'contentful';

import { ContentfulEntry, GetEntryOptions } from './types';

export default class ContentfulService {
  private static createContentfulClient(): AxiosInstance {
    const config = Config.get().contentful;
    const space = 'wveldzsk282j';

    const client = axios.create({
      baseURL: `${config.apiBaseUrl}/spaces/${space}/environments/${config.environment}/entries`,
      timeout: 10000,
      headers: { Authorization: `Bearer ${config.token}` },
    });

    return client;
  }

  private static async getEntry<T>(
    id: string,
    _options?: GetEntryOptions
  ): Promise<Entry<T>> {
    const res = await ContentfulService.createContentfulClient().get<Entry<T>>(
      `/${id}`
    );
    return res.data;
  }

  public static async getContenfulEntry(id: string): Promise<ContentfulEntry> {
    const entry = await ContentfulService.getEntry<ContentfulEntry>(id);
    return entry.fields;
  }
}
