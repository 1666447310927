export const dateOfBirthCognitoStandard = ({
  birth,
  reverse,
}: {
  birth: string;
  reverse?: boolean;
}) => {
  if (reverse) {
    const [y, m, d] = birth.split('-');
    return [m, d, y].join('/');
  }
  const [m, d, y] = birth.split('/');
  return [y, m, d].join('-');
};
