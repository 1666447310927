import styled from '@emotion/styled';
import { colors } from 'theme';

export const Container = styled.div<{ isSecondaryColor?: boolean }>(
  ({ isSecondaryColor }) => ({
    background: isSecondaryColor ? colors.paleBlue400 : colors.white,
    height: 730,
    maxHeight: '100%',
    width: 392,
    maxWidth: '100%',
    borderRadius: 16,
    padding: '16px 32px 32px 32px',
    display: 'flex',
    flexDirection: 'column',
  })
);
