import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { User } from 'types/User';

import { dateOfBirthCognitoStandard } from 'utils/dateOfBirthCognitoStandard';

import { appActions } from '.';

export const GET_CURRENT_USER_ACTION_TYPE = '@App/GetCurrentUser';
export const REFRESH_TOKEN_ACTION_TYPE = '@App/RefreshToken';
export const SIGNOUT_ACTION_TYPE = '@App/SignOut';

export const refreshToken = createAsyncThunk(
  REFRESH_TOKEN_ACTION_TYPE,
  async (_, { dispatch }) => {
    try {
      const session = await Auth.currentSession();

      const idToken = session?.getIdToken().getJwtToken();

      if (idToken) {
        dispatch(appActions.setToken(idToken));
      }
    } catch (_e) {
      dispatch(appActions.setToken(null));
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  GET_CURRENT_USER_ACTION_TYPE,
  async (_, { dispatch }) => {
    try {
      const session = await Auth.currentSession();

      const idToken = session?.getIdToken().getJwtToken();

      if (idToken) {
        dispatch(appActions.setToken(idToken));

        const { attributes } = await Auth.currentUserInfo();
        // const { id } = await Api.Users.getProfile();

        const currentUser: User = {
          email: attributes.email,
          firstName: attributes.given_name,
          lastName: attributes.family_name,
          phoneNumber: attributes.phone_number,
          dateOfBirth: dateOfBirthCognitoStandard({
            birth: attributes.birthdate,
            reverse: true,
          }),
          // capableId: id,
        };

        dispatch(appActions.setCurrentUser(currentUser));
      }
    } catch (_e) {
      dispatch(appActions.setToken(null));
    }
  }
);

export const signOut = createAsyncThunk(SIGNOUT_ACTION_TYPE, async () => {
  await Auth.signOut();
});
