import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from 'redux-injectors';
import { User } from 'types/User';

import { getCurrentUser } from './actions';
import { AppState } from './types';

export const initialState: AppState = {
  state: 'pending',
  token: null,
  currentUser: null,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<User | null>) => {
      if (action.payload) {
        state.currentUser = { ...state.currentUser, ...action.payload };
      } else {
        state.currentUser = null;
        state.token = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.pending, (state) => {
      state.state = 'pending';
    });
    builder.addCase(getCurrentUser.fulfilled, (state) => {
      state.state = 'idle';
    });
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.state = 'idle';
    });
  },
});

export const { actions: appActions, reducer } = slice;

export const useAppSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
