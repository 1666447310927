import { CircularProgress } from '@mui/material';

import LegalDocsBox from 'components/ContentfulLegalDocs';
import useContentfulEntry from 'hooks/useContentfulEntry';

const TermsConditions = () => {
  const content = useContentfulEntry('1qyVyPm6JpZ9nWFXFFcwoh');

  if (!content) {
    return <CircularProgress />;
  }

  return <LegalDocsBox content={content} />;
};

export default TermsConditions;
