import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { colors, theme } from 'theme';

import Typography from 'components/Typography';

export const StyledStepsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 32,
  padding: 32,
  width: '100%',
  maxWidth: 432,
  background: colors.white,
  borderRadius: 8,
  [theme.breakpoints.down('sm')]: {
    padding: 24,
    rowGap: 16,
  },
});

export const TextCircle = styled(Typography)({
  width: 40,
  height: 40,
  border: '2px solid',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
});

export const RowContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 24,
});
