import { TFunction } from 'react-i18next';
import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import { dateOfBirthCognitoStandard } from 'utils/dateOfBirthCognitoStandard';

import { messages } from './messages';

YupPassword(yup);

export const validationSchemas = (t: TFunction<'translation', undefined>) => ({
  firstName: yup.string().trim().required(t(messages.firstNameIsRequired())),
  lastName: yup.string().trim().required(t(messages.lastNameIsRequired())),
  birth: yup
    .string()
    .required(t(messages.birthIsRequired()))
    .test(t(messages.birthFormat()), t(messages.birthIsInvalid()), (val) =>
      handleDateOfBirthValidation(val!)
    ),
  phone: yup
    .string()
    .required(t(messages.phoneIsRequired()))
    .test(
      t(messages.phoneNumberFormat()),
      t(messages.phoneIsInvalid()),
      (val) => handlePhoneValidation(val!)
    ),
  password: yup
    .string()
    .password()
    .required()
    .minLowercase(1)
    .minUppercase(1)
    .minNumbers(1)
    .minSymbols(1)
    .min(10),
  confirmPassword: yup
    .string()
    .required(t(messages.passwordConfirmationIsRequired()))
    .oneOf([yup.ref('password'), null], t(messages.passwordsMustMatch())),
  email: yup
    .string()
    .email(t(messages.emailInvalid()))
    .required(t(messages.emailIsRequired())),
});

export const handlePhoneValidation = (
  number: string,
  country: CountryCode = 'US'
): boolean => {
  if (isValidPhoneNumber(number, country)) {
    const phoneNumber = parsePhoneNumber(number, country);
    return phoneNumber.country === country;
  }

  // Add the code below to allow polish phone numbers for development
  // if (isValidPhoneNumber(number, 'PL')) {
  //   const phoneNumber = parsePhoneNumber(number, 'PL');
  //   return phoneNumber.country === 'PL';
  // }

  return false;
};

export const handleDateOfBirthValidation = (birth: string) => {
  const parsedDate = new Date(dateOfBirthCognitoStandard({ birth }));

  const isValidDate = parsedDate instanceof Date;
  const notFutureDate = parsedDate < new Date();
  const notTooOldDate = parsedDate > new Date(1900, 0, 1);

  return isValidDate && notFutureDate && notTooOldDate;
};
