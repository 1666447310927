import { translations } from 'locales/translations';
import { _t } from 'locales/utils/messages';

export const messages = {
  firstNameIsRequired: () =>
    _t(
      translations.common.validationMessages.firstNameIsRequired,
      'Please enter your first name'
    ),
  lastNameIsRequired: () =>
    _t(
      translations.common.validationMessages.lastNameIsRequired,
      'Please enter your last name'
    ),
  birthIsRequired: () =>
    _t(
      translations.common.validationMessages.birthIsRequired,
      'Please enter your date of birth'
    ),
  birthFormat: () =>
    _t(
      translations.common.validationMessages.birthFormat,
      'Date of birth number format'
    ),
  birthIsInvalid: () =>
    _t(
      translations.common.validationMessages.birthIsInvalid,
      'Please use this format: MM/DD/YYYY'
    ),
  phone: () =>
    _t(
      translations.common.validationMessages.phone,
      'Phone number (273 456 7805)'
    ),
  phoneLabel: () =>
    _t(translations.common.validationMessages.phoneLabel, 'Phone number'),
  phoneIsRequired: () =>
    _t(
      translations.common.validationMessages.phoneIsRequired,
      'Please enter your phone number'
    ),
  phoneNumberFormat: () =>
    _t(
      translations.common.validationMessages.phoneNumberFormat,
      'Phone number format'
    ),
  phoneIsInvalid: () =>
    _t(
      translations.common.validationMessages.phoneIsInvalid,
      'Please enter a valid phone number'
    ),
  emailIsRequired: () =>
    _t(
      translations.common.validationMessages.emailIsRequired,
      'Email is required'
    ),
  emailInvalid: () =>
    _t(
      translations.common.validationMessages.emailInvalid,
      'Please enter a valid email address'
    ),
  passwordConfirmationIsRequired: () =>
    _t(
      translations.common.validationMessages.passwordConfirmationIsRequired,
      'Password confirmation is required'
    ),
  passwordsMustMatch: () =>
    _t(
      translations.common.validationMessages.passwordsMustMatch,
      'Make sure your passwords match'
    ),
  passwordCheckCharactersNumber: () =>
    _t(
      translations.common.validationMessages.passwordCheckCharactersNumber,
      'At least 10 characters'
    ),
  passwordCheckUppercase: () =>
    _t(
      translations.common.validationMessages.passwordCheckUppercase,
      '1 uppercase letter'
    ),
  passwordCheckLowercase: () =>
    _t(
      translations.common.validationMessages.passwordCheckLowercase,
      '1 lowercase letter'
    ),
  passwordCheckNumber: () =>
    _t(translations.common.validationMessages.passwordCheckNumber, '1 number'),
  passwordCheckSpecialCharacter: () =>
    _t(
      translations.common.validationMessages.passwordCheckSpecialCharacter,
      '1 special character (e.g., ! @ # ?)'
    ),
};
