import React, { useState } from 'react';
import { OutlinedInputProps } from '@mui/material';

import ErrorMessage from './ErrorMessage';
import { StyledFormControl, StyledInputLabel, StyledOutlinedInput } from './TextField.styled';
import TextFieldIcons from './TextFieldIcons';

interface Props extends OutlinedInputProps {
  label: string;
  marginBottom?: number;
  isPassword?: boolean;
  isValid?: boolean;
  errorMessage?: string;
}

export const TextField: React.FC<Props> = React.forwardRef(
  (
    { label, marginBottom = 0, isPassword = false, isValid = false, errorMessage, ...props },
    ref
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(!isPassword);

    return (
      <StyledFormControl
        variant="outlined"
        style={{ marginBottom }}
        isValid={isValid}
        isError={!!errorMessage}
      >
        <StyledInputLabel htmlFor={label} variant="outlined">
          {label}
        </StyledInputLabel>
        <StyledOutlinedInput
          id={label}
          ref={ref}
          type={passwordVisible ? 'text' : 'password'}
          label={label}
          endAdornment={
            <TextFieldIcons
              isPassword={isPassword}
              passwordVisible={passwordVisible}
              visibilityCallback={() => setPasswordVisible((v) => !v)}
              isValid={isValid}
            />
          }
          {...props}
        />
        {!isPassword && <ErrorMessage message={errorMessage} />}
      </StyledFormControl>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
