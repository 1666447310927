import styled from '@emotion/styled';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { colors } from 'theme';
import { fonts } from 'theme/fonts';

const handleColor = (isValid?: boolean, isError?: boolean) => {
  if (!isValid && !isError) return colors.darkGreen300;
  return isValid ? colors.limeGreen500 : colors.error.main;
};

export const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (propName) => propName !== 'isValid' && propName !== 'isError',
})<{ isValid?: boolean; isError?: boolean }>(({ isValid, isError }) => ({
  width: '100%',
  fieldset: {
    borderColor: handleColor(isValid, isError),
    borderWidth: isValid || isError ? 1.5 : 1,
  },
}));

export const StyledOutlinedInput = styled(OutlinedInput)({
  borderRadius: 8,
  padding: 0,
});

export const StyledInputLabel = styled(InputLabel)({
  ...fonts.Body1,
  color: colors.darkGreen700,
});
