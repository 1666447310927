import React, { PropsWithChildren, ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  primaryButton?: ReactNode;
  secondaryButton?: ReactNode;
}

const ContentWrapper: React.FC<PropsWithChildren<Props>> = ({
  primaryButton,
  secondaryButton,
  children,
  ...restProps
}) => (
  <Box
    flex={1}
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    overflow="hidden"
    {...restProps}
  >
    <Box overflow="scroll">{children}</Box>
    {primaryButton}
    {secondaryButton}
  </Box>
);

export default ContentWrapper;
