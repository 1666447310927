import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Routes } from 'navigation/routes';
import { theme } from 'theme';

import Typography from 'components/Typography';

import { Footer, LinkContainer, NavLinkNoUnderline } from './Footer.styled';
import { messages } from './messages';

const Layout: React.FC = () => {
  const { t } = useTranslation();

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const typoVariant = isSmScreen ? 'DecorBody1Med' : 'DecorBody1';
  return (
    <Footer>
      <Typography variant="DecorBody1">
        © {new Date().getFullYear()} {t(messages.copyright())}
      </Typography>
      <LinkContainer>
        <NavLinkNoUnderline to={Routes.Terms}>
          <Typography variant={typoVariant}>{t(messages.terms())}</Typography>
        </NavLinkNoUnderline>
        <NavLinkNoUnderline to={Routes.Privacy}>
          <Typography variant={typoVariant}>{t(messages.privacy())}</Typography>
        </NavLinkNoUnderline>
        <NavLinkNoUnderline to={Routes.Contact}>
          <Typography variant={typoVariant}>{t(messages.contact())}</Typography>
        </NavLinkNoUnderline>
      </LinkContainer>
    </Footer>
  );
};

export default Layout;
