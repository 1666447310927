import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer } from 'redux-injectors';

import { RegistrationState, RegistrationStep } from './types';

export const initialState: RegistrationState = {
  registrationStep: RegistrationStep.Account,
};

const slice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.registrationStep = action.payload;
    },
    updateUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    },
  },
});

export const { actions: registrationActions, reducer } = slice;

export const useRegistrationSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
