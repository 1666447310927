import { messages } from 'utils/validation/messages';

interface PasswordChecks {
  description: [string, ...any[]];
  validation: RegExp;
}

const passwordChecks: PasswordChecks[] = [
  {
    description: messages.passwordCheckCharactersNumber(),
    validation: /(?=.{10,})/,
  },
  {
    description: messages.passwordCheckNumber(),
    validation: /(?=\d)/,
  },
  {
    description: messages.passwordCheckUppercase(),
    validation: /(?=[A-Z])/,
  },
  {
    description: messages.passwordCheckLowercase(),
    validation: /(?=[a-z])/,
  },
  {
    description: messages.passwordCheckSpecialCharacter(),
    validation: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
  },
];

export const confirmPasswordCheck = messages.passwordsMustMatch();

export default passwordChecks;
