import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from './types/injector-typings';

export function createReducer(injectedReducers: InjectedReducersType = {}) {
  if (Object.keys(injectedReducers).length === 0) {
    return (state: any) => state;
  }

  const rootReducer = combineReducers({
    ...injectedReducers,
  });

  const reducerProxy = (state: any, action: AnyAction) => {
    return rootReducer(state, action);
  };

  return reducerProxy;
}
