import React, { PropsWithChildren } from 'react';

import { Container } from './Card.styled';
import CardHeader from './CardHeader';
import CardSteps from './CardSteps';

interface Props {
  title?: string;
  currentStep?: number;
  numberOfSteps?: number;
  isSecondaryColor?: boolean;
}

const Card: React.FC<PropsWithChildren<Props>> = ({
  title,
  currentStep,
  numberOfSteps,
  isSecondaryColor,
  children,
}) => {
  return (
    <Container isSecondaryColor={isSecondaryColor}>
      <CardHeader
        title={title}
        currentStep={currentStep}
        numberOfSteps={numberOfSteps}
      />
      <CardSteps
        currentStep={currentStep}
        numberOfSteps={numberOfSteps}
        isSecondaryColor={isSecondaryColor}
      />
      {children}
    </Container>
  );
};

export default Card;
