import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Routes } from 'navigation/routes';

const useMatchesLocations = (routes: Routes[]) => {
  const location = useLocation();
  const [matchesLocations, setMatchesLocations] = useState(false);

  useEffect(() => {
    setMatchesLocations(routes.some((route) => location.pathname.includes(route)));
  }, [location, routes]);

  return { matchesLocations };
};

export default useMatchesLocations;
