import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import Typography from 'components/Typography';

import { ValidationStatus } from './PasswordValidationCheck/PasswordValidationCheck';
import { messages } from './messages';
import passwordChecks, { confirmPasswordCheck } from './passwordChecks';
import PasswordValidationCheck from './PasswordValidationCheck';

interface Props {
  password?: string;
  confirmPassword?: string;
  primaryPasswordValidation?: boolean;
  marginBottom?: number;
}

const PasswordValidation: React.FC<Props> = ({
  password,
  confirmPassword,
  primaryPasswordValidation,
  marginBottom = 0,
}) => {
  const { t } = useTranslation();

  const handleValidationStatus = (elementValid: boolean) => {
    if (!password) {
      return ValidationStatus.NotChecked;
    } else if (elementValid) {
      return ValidationStatus.Valid;
    } else {
      return ValidationStatus.Invalid;
    }
  };

  return (
    <Box style={{ marginBottom }} paddingX="8px">
      {primaryPasswordValidation ? (
        <>
          <Typography variant="Helper1" marginBottom={6}>
            {t(messages.mustInclude())}:
          </Typography>
          {passwordChecks.map(({ validation, description }, ix) => (
            <Typography variant="Helper1" display="block" key={ix}>
              <PasswordValidationCheck
                status={handleValidationStatus(validation.test(password || ''))}
                description={t(description)}
              />
            </Typography>
          ))}
        </>
      ) : (
        <PasswordValidationCheck
          description={t(confirmPasswordCheck)}
          status={
            confirmPassword
              ? handleValidationStatus(confirmPassword === password)
              : ValidationStatus.NotChecked
          }
        />
      )}
    </Box>
  );
};

export default PasswordValidation;
