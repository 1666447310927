import { Interpolation, Theme } from '@emotion/react';
import { ButtonProps } from '@mui/material/Button';

import { colors } from './colors';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    disabled: true;
  }
}

type ButtonVariant = {
  props: Partial<ButtonProps<'button'>>;
  style: Interpolation<{
    theme: Theme;
  }>;
}[];

export const buttons: ButtonVariant = [
  {
    props: { variant: 'primary' },
    style: {
      backgroundColor: colors.limeGreen500,
      color: colors.darkGreen900,
      '&:hover': {
        backgroundColor: colors.limeGreen500,
      },
    },
  },
  {
    props: { variant: 'secondary' },
    style: {
      backgroundColor: colors.darkGreen900,
      color: colors.white,
      '&:hover': {
        backgroundColor: colors.darkGreen900,
      },
    },
  },
  {
    props: { variant: 'disabled' },
    style: {
      backgroundColor: colors.darkGreen100,
      color: colors.darkGreen400,
    },
  },
];
