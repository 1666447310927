import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

export const TextBold = styled.span({
  fontWeight: 'bold',
});

export const TextUnderline = styled.span({
  textDecoration: 'underline',
});

export const StyledNavLink = styled(NavLink)({
  textDecoration: 'underline',
  color: 'inherit',
});
