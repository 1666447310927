import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { Routes } from 'navigation/routes';

import useMatchesLocations from 'hooks/useMatchesLocations';

import Footer from '../Footer';
import Header from '../Header';

import { Container, Main } from './Layout.styled';

const Layout: React.FC<PropsWithChildren> = () => {
  const { matchesLocations } = useMatchesLocations([Routes.GetStarted]);

  return (
    <Container isSecondaryBackground={matchesLocations}>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </Container>
  );
};

export default Layout;
