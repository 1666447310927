import React from 'react';
import Lottie from 'react-lottie';

import * as animationData from 'assets/animations/intro_animation.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface Props {
  width?: number;
  height?: number;
}

const LottieAnimation: React.FC<Props> = ({ width = 280, height = 280, ...restProps }) => (
  <Lottie options={defaultOptions} width={width} height={height} {...restProps} />
);

export default LottieAnimation;
