import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { theme } from 'theme';

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 48,
  [theme.breakpoints.down('md')]: {
    gap: 8,
  },
  [theme.breakpoints.down('sm')]: {
    gap: 28,
  },
});

export const ContentContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '220px auto 220px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'auto',
  },
});

export const UnderlineContainer = styled.span({
  position: 'relative',
});

export const UnderlineImage = styled.img({
  position: 'absolute',
  left: -150,
  bottom: -10,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
});
