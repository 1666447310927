import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { registrationActions } from 'pages/GetStarted/slice';
import { RegistrationStep } from 'pages/GetStarted/slice/types';
import { useDispatch } from 'store/utils/react-redux';
import * as yup from 'yup';

import Button from 'components/Button';
import Card from 'components/Card';
import Checkbox from 'components/Checkbox';
import ContentfulRichText from 'components/ContentfulRichText';
import ContentWrapper from 'components/ContentWrapper';
import PasswordValidation from 'components/PasswordValidation';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import useContentfulEntry from 'hooks/useContentfulEntry';
import { validationSchemas } from 'utils/validation/validationSchemas';

import { messages } from './messages';

export interface AccountFormValues {
  email: string;
  password: string;
  confirmPassword: string;
}

const validationSchema = (t: TFunction<'translation', undefined>) =>
  yup.object().shape({
    email: validationSchemas(t).email,
    password: validationSchemas(t).password,
    confirmPassword: validationSchemas(t).confirmPassword,
  });

const AccountCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const content = useContentfulEntry('62k8FcMlZSsnMWsm2qNKMd');
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    register,
    watch,
  } = useForm<AccountFormValues>({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(validationSchema(t)),
    mode: 'onBlur',
  });

  const handleSubmitAccountValues = (values: AccountFormValues) => {
    const { email, password } = values;
    dispatch(registrationActions.updateUserData({ email, password }));
    dispatch(registrationActions.setCurrentStep(RegistrationStep.PhoneNumber));
  };

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  if (!content) {
    return <CircularProgress />;
  }

  return (
    <Card currentStep={1} title={content.title}>
      <ContentWrapper
        component="form"
        onSubmit={handleSubmit(handleSubmitAccountValues)}
        primaryButton={<Button type="submit" disabled={!isValid} />}
      >
        <Typography variant="Headline2" marginBottom="24px">
          {content.content}
        </Typography>
        <TextField
          {...register('email')}
          errorMessage={errors.email?.message}
          isValid={touchedFields.email && !errors.email?.message}
          label={t(messages.emailAddressLabel())}
          marginBottom={24}
        />
        <TextField
          {...register('password')}
          errorMessage={errors.password?.message}
          isValid={touchedFields.password && !errors.password?.message}
          label={t(messages.passwordLabel())}
          marginBottom={16}
          isPassword
        />
        <PasswordValidation password={password} marginBottom={24} primaryPasswordValidation />
        <TextField
          {...register('confirmPassword')}
          errorMessage={errors.confirmPassword?.message}
          isValid={touchedFields.confirmPassword && !errors.confirmPassword?.message}
          label={t(messages.repeatPasswordLabel())}
          marginBottom={12}
          isPassword
        />
        <PasswordValidation
          password={password}
          confirmPassword={confirmPassword}
          marginBottom={16}
        />
        <Checkbox
          label={<ContentfulRichText variant="Body1" content={content.richContent} />}
          checked={agreedToTerms}
          handleChange={(e) => setAgreedToTerms(e.target.checked)}
        />
      </ContentWrapper>
    </Card>
  );
};

export default AccountCard;
