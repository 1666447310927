import React from 'react';
import { useSelector } from 'react-redux';

import AccountCard from './components/AccountCard';
import { selectRegistrationStep } from './slice/selectors';
import { useRegistrationSlice } from './slice';

const GetStarted: React.FC = () => {
  useRegistrationSlice();
  const registrationStep = useSelector(selectRegistrationStep);

  //TODO: add switch between registration steps

  return <AccountCard />;
};

export default GetStarted;
