declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    MainTitle: true;
    SecondTitle: true;
    Title: true;
    Headline1: true;
    Headline2: true;
    SubHeadline1: true;
    SubHeadline1Med: true;
    SubHeadline2: true;
    SubHeadline2Med: true;
    Body1: true;
    Body1Bold: true;
    DecorBody1: true;
    DecorBody1Med: true;
    Helper1: true;
    Helper1Bold: true;
    Helper2: true;
    Helper2Med: true;
    Link: true;
    Byline1: true;
    Byline1Med: true;
    Byline2: true;
    Byline2Med: true;
  }
}

export const variantMapping = {
  MainTitle: 'h1',
  SecondTitle: 'h1',
  Title: 'h1',
  Headline1: 'h2',
  Headline2: 'h2',
  SubHeadline1: 'h2',
  SubHeadline1Med: 'h2',
  SubHeadline2: 'h2',
  SubHeadline2Med: 'h2',
  Body1: 'p',
  Body1Bold: 'p',
  DecorBody1: 'p',
  DecorBody1Med: 'p',
  Helper1: 'span',
  Helper1Bold: 'span',
  Helper2: 'span',
  Helper2Med: 'span',
  Link: 'span',
  Byline1: 'span',
  Byline1Med: 'span',
  Byline2: 'span',
  Byline2Med: 'span',
};

export const fonts = {
  MainTitle: {
    fontFamily: 'RocGrotesk-Regular',
    fontSize: '44px',
    lineHeight: '52px',
    letterSpacing: '0.3px',
  },
  SecondTitle: {
    fontFamily: 'RocGrotesk-Regular',
    fontSize: '40px',
    lineHeight: '48px',
    letterSpacing: '0.3px',
  },
  Title: {
    fontFamily: 'RocGrotesk-Medium',
    fontSize: '36px',
    lineHeight: '48px',
    letterSpacing: '0.3px',
  },
  Headline1: {
    fontFamily: 'RocGrotesk-Medium',
    fontSize: '28px',
    lineHeight: '36px',
    letterSpacing: '0.3px',
  },
  Headline2: {
    fontFamily: 'RocGrotesk-Medium',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.3px',
    fontWeight: 500,
  },
  SubHeadline1: {
    fontFamily: 'RocGrotesk-Regular',
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: '0.3px',
  },
  SubHeadline1Med: {
    fontFamily: 'RocGrotesk-Medium',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.3px',
  },
  SubHeadline2: {
    fontFamily: 'RocGrotesk-Regular',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.3px',
  },
  SubHeadline2Med: {
    fontFamily: 'RocGrotesk-Medium',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.3px',
  },
  Body1: {
    fontFamily: 'Aeonik-Regular',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
  },
  Body1Bold: {
    fontFamily: 'Aeonik-Bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
  },
  DecorBody1: {
    fontFamily: 'RocGrotesk-Regular',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.3px',
  },
  DecorBody1Med: {
    fontFamily: 'RocGrotesk-Medium',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.3px',
  },
  Helper1: {
    fontFamily: 'Aeonik-Regular',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
  Helper1Bold: {
    fontFamily: 'Aeonik-Bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
  Helper2: {
    fontFamily: 'RocGrotesk-Regular',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.3px',
  },
  Helper2Med: {
    fontFamily: 'RocGrotesk-Medium',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.3px',
  },
  Link: {
    fontFamily: 'Aeonik-Bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
  },
  Byline1: {
    fontFamily: 'RocGrotesk-Regular',
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.3px',
  },
  Byline1Med: {
    fontFamily: 'RocGrotesk-Medium',
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.3px',
  },
  Byline2: {
    fontFamily: 'RocGrotesk-Regular',
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.3px',
  },
  Byline2Med: {
    fontFamily: 'RocGrotesk-Medium',
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.3px',
  },
};
