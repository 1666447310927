import React from 'react';

import Typography from 'components/Typography';

import { CardTitle } from './CardHeader.styled';

interface Props {
  title?: string;
  currentStep?: number;
  numberOfSteps?: number;
}

const CardHeader: React.FC<Props> = ({
  title,
  currentStep,
  numberOfSteps = 3,
}) => {
  const displaySteps = currentStep ? `${currentStep}/${numberOfSteps}` : null;

  return (
    <CardTitle>
      <Typography
        variant="Helper2Med"
        color="darkGreen700"
        textAlign="center"
        display="block"
      >
        {displaySteps} {title}
      </Typography>
    </CardTitle>
  );
};

export default CardHeader;
