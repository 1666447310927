import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

import Footer from '../Footer';
import Header from '../Header';

import { BackgroundContainer, Container, Main, PadContainer } from './LegalDocsLayout.styled';

const LegalDocsLayout: React.FC<PropsWithChildren> = () => {
  return (
    <Container>
      <BackgroundContainer>
        <PadContainer>
          <Header />
        </PadContainer>
      </BackgroundContainer>
      <BackgroundContainer>
        <Main>
          <Outlet />
        </Main>
      </BackgroundContainer>
      <PadContainer>
        <Footer />
      </PadContainer>
    </Container>
  );
};

export default LegalDocsLayout;
