import { useEffect, useState } from 'react';
import ContentfulService from 'services/contenful';
import { ContentfulEntry } from 'services/contenful/types';

const useContentfulEntry = (id: string) => {
  const [content, setContent] = useState<ContentfulEntry | undefined>();

  useEffect(() => {
    if (content) return;

    (async () => {
      const entryFields = await ContentfulService.getContenfulEntry(id);
      setContent(entryFields);
    })();
  }, [content, id]);

  return content;
};

export default useContentfulEntry;
