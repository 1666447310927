import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectAppState, selectCurrentUser } from 'app/slice/selectors';
import { Routes } from 'navigation/routes';

const ProtectedRoutes = () => {
  const currentUser = useSelector(selectCurrentUser);
  const appState = useSelector(selectAppState);
  const location = useLocation();

  if (!currentUser && appState === 'idle') {
    return <Navigate to={Routes.Home} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
