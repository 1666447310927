import styled from '@emotion/styled';
import { Button, CircularProgress } from '@mui/material';
import { fonts } from 'theme/fonts';

export const StyledButton = styled(Button)({
  borderRadius: 32,
  transition: 'opacity .3s',
  textTransform: 'none',
  height: 56,
  width: '100%',
  maxWidth: 328,
  flexShrink: 0,
  marginTop: 16,
  ...fonts.DecorBody1Med,
  '&:hover': {
    opacity: 0.9,
  },
});

export const StyledCircularProgress = styled(CircularProgress)({
  color: 'inherit',
});
