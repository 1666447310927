import React, { ReactNode } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, MARKS, Node } from '@contentful/rich-text-types';
import { fonts } from 'theme/fonts';

import Typography from 'components/Typography';

import { StyledNavLink, TextBold, TextUnderline } from './ContentfulRichText.styled';

type FontVariant = keyof typeof fonts;

const options = (variant?: FontVariant) => ({
  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => <TextBold>{text}</TextBold>,
  },
  [MARKS.UNDERLINE]: (text: ReactNode) => <TextUnderline>{text}</TextUnderline>,
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: Node, children: ReactNode) => (
      <Typography variant={variant}>{children}</Typography>
    ),
    [INLINES.HYPERLINK]: (node: Node, children: ReactNode) => (
      <StyledNavLink to={node.data.uri}>{children}</StyledNavLink>
    ),
  },
});

interface Props {
  content?: Document;
  variant?: FontVariant;
}

const ContentfulRichText: React.FC<Props> = ({ content, variant }) => {
  if (!content) return null;

  return <>{documentToReactComponents(content, options(variant))}</>;
};

export default ContentfulRichText;
