export const colors = {
  primary: { main: '#022F2C', light: '#355956' },
  secondary: { main: '#355956' },
  success: { main: '#51A05E' },
  error: { main: '#CE2727' },
  errorMedium: '#F7C0C0',
  errorLight: '#FCEAEA',
  darkGreen100: '#F3F5F5',
  darkGreen200: '#E6EAE9',
  darkGreen300: '#D2DBDA',
  darkGreen400: '#9AACAB',
  darkGreen500: '#678280',
  darkGreen600: '#4E6D6B',
  darkGreen700: '#355956',
  darkGreen800: '#1B4441',
  darkGreen900: '#022F2C',
  limeGreen100: '#F0F7E5',
  limeGreen200: '#D5E7B5',
  limeGreen300: '#C8E3A0',
  limeGreen400: '#C8E3A0',
  limeGreen500: '#97C354',
  limeGreen600: '#7C9F45',
  limeGreen700: '#637E37',
  limeGreen800: '#496028',
  limeGreen900: '#2C3B1A',
  paleBlue100: '#F1F6FA',
  paleBlue200: '#E6F1F3',
  paleBlue300: '#D3E4EC',
  paleBlue400: '#C1DBE6',
  paleBlue500: '#9BB9C1',
  paleBlue600: '#88ABB5',
  paleBlue700: '#84979E',
  paleBlue800: '#6B787E',
  paleBlue900: '#363E41',
  neutralLight050: 'rgba(249, 250, 251, 0.5)',
  lavendar: '#E3D7E4',
  eggshell: '#FCF6EE',
  peach: '#FA8A63',
  gray: '#D4DDE2',
  beige: '#FAEDDC',
  paleOrange: '#FFD3AA',
  white: '#FFFFFF',
};
