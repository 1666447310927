import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en/translation.json';
import { convertLanguageJsonToObject } from './translations';

export const translationsJson = {
  en: {
    translation: en,
  },
};

convertLanguageJsonToObject(en);

const supportedLngs = Object.keys(translationsJson);

export const i18n = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources: translationsJson,
    fallbackLng: 'en',
    supportedLngs,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });
