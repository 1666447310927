import { useCallback, useEffect } from 'react';
import { HubCallback } from '@aws-amplify/core';
import { appActions, useAppSlice } from 'app/slice';
import { getCurrentUser } from 'app/slice/actions';
import { Hub } from 'aws-amplify';
import { AuthEventType } from 'enums/AuthEventType';
import { useDispatch } from 'store/utils/react-redux';

const useAuth = () => {
  useAppSlice();
  const dispatch = useDispatch();

  const listener: HubCallback = useCallback(
    ({ payload: { event } }) => {
      switch (event) {
        case AuthEventType.TokenRefresh:
        case AuthEventType.TokenRefreshFailure:
          dispatch(getCurrentUser());
          break;
        case AuthEventType.SignIn:
        case AuthEventType.AutoSignIn:
          dispatch(getCurrentUser());
          break;
        case AuthEventType.SignOut:
        case AuthEventType.SignInFailure:
          dispatch(appActions.setCurrentUser(null));
          break;
      }
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getCurrentUser());
    Hub.listen('auth', listener);
    return () => Hub.remove('auth', listener);
  }, [dispatch, listener]);
};

export default useAuth;
