import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/types/RootState';

import { initialState } from '.';

const selectSlice = (state: RootState) => state?.app || initialState;

export const selectApp = createSelector([selectSlice], (state) => state);

export const selectCurrentUserState = createSelector(
  [selectSlice],
  (state) => state.currentUser
);

export const selectCurrentUser = createSelector(
  [selectSlice],
  (state) => state.currentUser
);

export const selectAppState = createSelector(
  [selectSlice],
  (state) => state.state
);
