import { CircularProgress } from '@mui/material';

import LegalDocsBox from 'components/ContentfulLegalDocs';
import useContentfulEntry from 'hooks/useContentfulEntry';

const PrivacyPolicy = () => {
  const content = useContentfulEntry('3mCHrrDxN2Z5lowahVWbfo');

  if (!content) {
    return <CircularProgress />;
  }

  return <LegalDocsBox content={content} />;
};

export default PrivacyPolicy;
