import 'locales/i18n';

import { Amplify } from 'aws-amplify';
import Config from 'config';
import Navigation from 'navigation';

import useAuth from 'hooks/useAuth';

Amplify.configure(Config.get().amplify);

function App() {
  useAuth();

  return <Navigation />;
}

export default App;
