import { Box } from '@mui/material';
import { ContentfulEntry } from 'services/contenful/types';

import ContentfulRichText from 'components/ContentfulRichText';
import Typography from 'components/Typography';

import { ContentContainer, ElementsContainer } from './ContentfulLegalDocs.styled';

interface Props {
  content: ContentfulEntry;
}

const ContentfulLegalDocs: React.FC<Props> = ({ content }) => {
  return (
    <Box paddingTop="32px">
      <ContentContainer>
        <ElementsContainer>
          <Typography variant="SecondTitle" marginBottom="16px">
            {content.title}
          </Typography>
          <Typography marginBottom="56px">{content.content}</Typography>
        </ElementsContainer>
      </ContentContainer>
      <ContentContainer paddingTop="48px" secondaryBackground>
        <ElementsContainer>
          <ContentfulRichText content={content.richContent} />
        </ElementsContainer>
      </ContentContainer>
    </Box>
  );
};

export default ContentfulLegalDocs;
