import React from 'react';
import { NavLink } from 'react-router-dom';
import { Routes } from 'navigation/routes';

import LogoSvg from 'assets/svg/logo.svg';

import { Header } from './Header.styled';

const Layout: React.FC = () => {
  return (
    <Header>
      <NavLink to={Routes.Home}>
        <img src={LogoSvg} alt="pip logo" />
      </NavLink>
    </Header>
  );
};

export default Layout;
