import { configureStore } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import logger from 'redux-logger';

import { createReducer } from './reducers';

export function configureAppStore() {
  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga: () => {},
    }),
  ];

  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) => {
      // if (process.env.REACT_APP_ENV === `development`) {
      //   return getDefaultMiddleware({ serializableCheck: false }).concat(
      //     logger
      //   );
      // }
      return getDefaultMiddleware({ serializableCheck: false });
    },
    enhancers,
  });

  return store;
}
