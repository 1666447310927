import React, { PropsWithChildren } from 'react';
import { BoxProps } from '@mui/material';
import { colors } from 'theme';

import Typography from 'components/Typography';

import { RowContainer, StyledStepsBox, TextCircle } from './StepsBox.styled';

interface Props extends BoxProps {
  activeIndex?: number;
  contentList?: string[];
}

export const StepsBox: React.FC<PropsWithChildren<Props>> = ({
  activeIndex = 0,
  contentList = [],
  ...props
}) => {
  const getFontColor = (index: number): keyof typeof colors =>
    activeIndex === index ? 'darkGreen900' : 'darkGreen500';

  return (
    <StyledStepsBox {...props}>
      {contentList?.map((el, index: number) => {
        const color = getFontColor(index);
        return (
          <RowContainer key={index}>
            <TextCircle variant={'SubHeadline1'} color={color}>
              {index + 1}
            </TextCircle>
            <Typography
              variant={'SubHeadline1'}
              color={color}
              sx={{
                textDecoration: index < activeIndex ? 'line-through' : '',
              }}
            >
              {el}
            </Typography>
          </RowContainer>
        );
      })}
    </StyledStepsBox>
  );
};

StepsBox.displayName = 'StepsBox';

export default StepsBox;
