import React from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import GetStarted from 'pages/GetStarted';
import Home from 'pages/Home';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsConditions from 'pages/TermsConditions';

import Layout from 'components/Layout';
import LegalDocsLayout from 'components/LegalDocsLayout';

import ProtectedRoutes from './components/ProtectedRoutes';
import { Routes } from './routes';

const Navigation = () => {
  return (
    <ReactRoutes>
      <Route element={<Layout />}>
        <Route path={Routes.Home} element={<Home />} />
        <Route path={Routes.GetStarted} element={<GetStarted />} />
        <Route element={<ProtectedRoutes />}>
          {/* // TODO: add future protected routes here*/}
        </Route>
      </Route>
      <Route element={<LegalDocsLayout />}>
        <Route path={Routes.Privacy} element={<PrivacyPolicy />} />
        <Route path={Routes.Terms} element={<TermsConditions />} />
      </Route>
    </ReactRoutes>
  );
};

export default Navigation;
