import React, { ReactNode } from 'react';
import FormGroup from '@mui/material/FormGroup';

import { ReactComponent as CheckMark } from 'assets/svg/checkbox-mark.svg';

import {
  CheckedCheckbox,
  DefaultCheckbox,
  HiddenCheckbox,
  StyledFormControlLabel,
} from './Checkbox.styled';

interface Props {
  label: ReactNode;
  checked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: React.FC<Props> = ({ label, checked, handleChange }) => (
  <FormGroup>
    <StyledFormControlLabel
      control={
        <>
          <HiddenCheckbox type="checkbox" onChange={handleChange} />
          {checked ? (
            <CheckedCheckbox>
              <CheckMark />
            </CheckedCheckbox>
          ) : (
            <DefaultCheckbox />
          )}
        </>
      }
      label={label}
    />
  </FormGroup>
);

export default Checkbox;
