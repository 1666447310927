import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colors } from 'theme';

export const ContentContainer = styled(Box)<{ secondaryBackground?: boolean }>(
  ({ secondaryBackground }) => ({
    background: secondaryBackground ? colors.white : 'none',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    padding: 24,
  })
);
export const ElementsContainer = styled(Box)({
  maxWidth: 560,
});
