import React from 'react';
import { Box } from '@mui/material';

import { ReactComponent as ErrorIcon } from 'assets/svg/error-icon.svg';
import Typography from 'components/Typography';

interface Props {
  message?: string;
}

const ErrorMessage: React.FC<Props> = ({ message }) => {
  if (!message) return null;

  return (
    <Box display="flex" alignItems="center" columnGap="4px" marginTop="6px">
      <ErrorIcon />
      <Typography variant="Helper1" color="error">
        {message}
      </Typography>
    </Box>
  );
};

export default ErrorMessage;
